@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
  font-family: "DM Sans", sans-serif;
}

@font-face {
  font-family: "DM Sans";
  src: url("../src/Fonts/DMSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "DM Sans";
  src: url("../src/Fonts/DMSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "DM Sans";
  src: url("../src/Fonts/DMSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "DM Sans";
  src: url("../src/Fonts/DMSans-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "DM Sans";
  src: url("../src/Fonts/DMSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "DM Sans";
  src: url("../src/Fonts/DMSans-Light.ttf") format("truetype");
  font-weight: 300;
}
